<template>
  <div class="manageBox">
    <div class="topBox">
      <div class="goBack" @click="goBack()">
        <img src="../../../image/back.png" alt />
      </div>
      <div class="h1">偏好管理</div>
      <div class="h5">为了给您推荐更合适的内容，请完善以下信息</div>
    </div>
    <div class="areaSize">您感兴趣的地区</div>
    <div class="cascaderBox">
      <van-field
        v-model="fieldValue"
        is-link
        readonly
        label="省/市（县）"
        placeholder="请选择所在地区"
        @click="cascaderShow = true"
      />
      <van-popup v-model="cascaderShow" round position="bottom">
        <van-cascader
          v-model="cascaderValue"
          title="请选择所在地区"
          :options="options"
          active-color="#1989fa"
          @close="cascaderShow = false"
          @finish="onFinish"
          @change="onCascaderChange"
        />
      </van-popup>
    </div>
    <div class="grad">您感兴趣的学段</div>
    <div class="gradBox">
      <div
        class="gradStyle"
        v-for="(item,index) in radioList"
        :key="index"
        :class="tabShowActive === item.id  ? 'tabShowactive' : 'gradStyle'"
        @click="tabShowEvent(item,index)"
      >{{item.value}}</div>
    </div>
    <div class="fooBtn" @click="submit()">确定</div>
  </div>
</template>

<script>
import {
  dictionary,
  regionList,
  infoPage,
  preferenceUpdate
} from "../../../api/lookAt";
import { Toast } from "vant";
export default {
  data() {
    return {
      cascaderValue: "",
      fieldValue: "",
      cascaderShow: false, //所在地区弹框状态
      options: [],
      radioList: [],
      ids: [],
      tabShowActive: "",
      manageObj: {
        // userId: "", //用户id
        provinceId: "", //省id
        cityId: "", //市id
        period: "" //学段
      }
    };
  },
  mounted() {
    // this.manageObj.userId = this.$store.state.user.id;
    regionList().then(res => {
      console.log(res.data.data, "地区");
      this.options = res.data.data;
      //   this.$store.commit("optionsList", res.data.data);

      this.options = res.data.data.map(item => {
        item.text = item.name;
        item.value = item.id;
        if (item.children.length > 0 || item.children != null) {
          item.children.map(i => {
            i.text = i.name;
            i.value = i.id;
          });
        }
      });
    });
    dictionary({ parentId: 13 }).then(res => {
      console.log(res, "学段");
      res.data.data.forEach(item => {
        item.status = false;
      });

      this.radioList = res.data.data;
      console.log(this.radioList);
    });
  },
  methods: {
    goBack() {
      this.$router.go(-1);
    },
    onFinish({ selectedOptions }) {
      // console.log(selectedOptions);
      this.cascaderShow = false;
      this.fieldValue = selectedOptions.map(option => option.text).join("/");
    },
    // 地区change事件
    onCascaderChange(value) {
      console.log(value);
      console.log(this.cascaderValue, "++++++++++++");
      if (value.selectedOptions.length === 2) {
        this.manageObj.provinceId = value.selectedOptions[0].id;
        this.manageObj.cityId = value.selectedOptions[1].id;
      }
    },
    tabShowEvent(item, index) {
      this.tabShowActive = item.id;
      console.log(item);
      this.manageObj.period = item.key;
      // this.ids = [];
      // console.log(item, index);
      // this.radioList[index].status = !item.status;
      // console.log(this.radioList);
      // this.radioList.forEach(i => {
      //   if (i.status) {
      //     this.ids.push(i.id);
      //   }
      // });
      // this.$forceUpdate();
    },
    submit() {
      preferenceUpdate(this.manageObj).then(res => {
        if (res.data.code === 0) {
          Toast("设置成功");
          this.$router.go(-1);
        } else {
          Toast(res.data.msg);
        }
        console.log(res, "偏好管理");
      });
    }
  }
};
</script>

<style scoped>
.topBox {
  width: 750px;
  height: 450px;
  background-image: url("../../../image/messImg/gl.png");
  background-size: 100% 100%;
}
.goBack img {
  width: 48px;
  height: 48px;
  margin-top: 60px;
  margin-left: 32px;
}
.h1 {
  font-size: 48px;
  font-weight: 700;
  color: #102a58;
  margin-top: 30px;
  margin-left: 32px;
}
.h5 {
  width: 468px;
  font-weight: 400;
  color: rgba(0, 0, 0, 0.4);
  font-size: 28px;
  margin-top: 28px;
  margin-left: 32px;
}
.areaSize {
  font-size: 32px;
  font-weight: 500;
  color: rgba(0, 0, 0, 0.9);
  margin-top: 48px;
  margin-left: 32px;
}
.cascaderBox {
  width: 686px;
  height: 140px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-left: 32px;

  /* border-bottom: 2px solid #ebedf0; */
}
/deep/.van-cell {
  padding-left: 0px;
}
.grad {
  font-size: 32px;
  font-weight: 500;
  color: rgba(0, 0, 0, 0.9);
  margin-left: 32px;
  margin-top: 20px;
}
.gradBox {
  display: flex;
  flex-wrap: wrap;
}
.gradStyle {
  height: 74px;
  background: #f7f8fa;
  border-radius: 16px;
  padding-left: 48px;
  padding-right: 48px;
  font-size: 28px;
  font-weight: 400;
  color: rgba(0, 0, 0, 0.9);
  margin-top: 48px;
  line-height: 74px;
  margin-left: 32px;
}
.tabShowactive {
  background: #0957c3;
  color: #ffffff;
}
.fooBtn {
  width: 686px;
  height: 90px;
  background: #0957c3;
  border-radius: 16px;
  opacity: 1;
  font-size: 28px;
  font-weight: 500;
  color: #ffffff;
  text-align: center;
  line-height: 90px;
  position: fixed;
  bottom: 40px;
  left: 32px;
}
</style>
